<template>
  <WeContainer header="ture" bread-crumb="" v-bind:actions="actions">
    <WeTable
      v-bind:index="false"
      v-bind:data="emailList.list"
      v-bind:allowSortIndex="false"
      v-bind:columns="columns"
      v-bind:actions="tableActions"
      v-bind:ajax="true"
      v-bind:fetch-on-mount="true"
      v-bind:loading="loading"
      v-bind:table-result="tableResult"
      v-on:on-action="onAction"
      v-on:make-request="updateTemplateList"
      v-bind:componentName="componentName"
      ref="emailListTable"
    ></WeTable>
  </WeContainer>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "List",
  data() {
    return {
      componentName: "emailLists",
      loading: false,
      actions: [
        {
          text: "Yeni E-Posta Listesi",
          path: "email-lists/new",
        },
        {
          text: "Çöp Kutusu",
          class: "btn btn-outline-danger float-right",
          icon: "fas fa-trash",
          path: "email-lists/trash",
        },
      ],
      tableActions: [
        {
          icon: "fas fa-edit",
          class: "btn-outline-primary",
          action: "edit",
        },
        {
          icon: "fas fa-trash",
          class: "btn-outline-danger ml-2",
          action: "remove",
        },
      ],
      columns: [
        { name: "title", th: "Başlık", type: "string" },
        { name: "members_count", th: "Üye Sayısı", type: "number" },
      ],
      tableResult: {
        item_count: 1,
        current_page: 1,
        total_page: 1,
        total_items: 1,
      },
    };
  },
  methods: {
    ...mapActions("emailList", ["getList", "delete"]),
    onAction(data) {
      if (data.key == "remove") {
        this.onDelete(data.row, data.index);
      } else if (data.key == "edit") {
        this.onEdit(data.row);
      }
    },
    checkFilterStorage() {
      return helper.checkFilterStorage(this.componentName);
    },
    onEdit(row) {
      this.$router.replace("email-lists/detail/" + row.id);
    },
    onDelete(row) {
      let message = `${row.title} başlıklı Listeyi silmek istediğinize emin misiniz ?`;

      this.$swal({
        title: message,
        html: `Bu işlemi <b class="text-danger">Çöp Kutusu</b> bölümünden geri alabilirsiniz.`,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.delete({
            id: row.id,
            onSuccess: (result) => {
              if (result && result.data && result.data.status !== "error") {
                this.$toast.success("Liste Başarıyla Silindi");
              } else {
                this.$swal({
                  title: "Liste Silinemedi",
                  text: result.data.message,
                  icon: "error",
                  confirmButtonText: "Tamam",
                });
              }
            },
          });
        }
      });
    },
    onSearch() {
      this.$refs.emailListTable.currentPage = 1;
      this.updateTemplateList();
    },
    updateTemplateList(requestData = null) {
      requestData = requestData || {
        page: 1,
        limit: 25,
      };

      this.loading = true;
      this.getList({
        table: requestData,
        onSuccess: (result) => {
          helper.copy(result, this.tableResult, ["items", "status"]);
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
    checkPermission(code) {
      return permission.check("emailList", code);
    },
  },
  computed: {
    ...mapState({
      emailList: (state) => state.emailList,
    }),
  },
};
</script>

